import React, { useState, useEffect } from 'react';
import './App.css';

function App() {

    const githubBranch = process.env.GITHUB_REF || 'Unspecified';

    const [iflexIsWaxing, setIflexIsWaxing] = useState(false);
    const [iflexIsVisible, setIflexIsVisible] = useState(true);

    const handleIflexMouseOver = () => () => {
        setIflexIsWaxing(true);
        setIflexIsVisible(true);
    };

    useEffect(() => {
        let fadeOutTimeout;
        let hideTimeout;

        if (iflexIsWaxing) {
            // Start fade-out after 2 seconds of being fully visible
            fadeOutTimeout = setTimeout(() => {
                setIflexIsWaxing(false);
            }, 2000);

            // Hide image 3 seconds after starting fade-out
            hideTimeout = setTimeout(() => {
                setIflexIsVisible(false);
            }, 5000);
        }

        return () => {
            clearTimeout(fadeOutTimeout);
            clearTimeout(hideTimeout);
        };
    }, [iflexIsWaxing]);

    const [landIsWaxing, setLandIsWaxing] = useState(false);
    const [landIsVisible, setLandIsVisible] = useState(true);

    const handleLandMouseOver = () => () => {
        setLandIsWaxing(true);
        setLandIsVisible(true);
    };

    useEffect(() => {
        let fadeOutTimeout;
        let hideTimeout;

        if (landIsWaxing) {
            // Start fade-out after 2 seconds of being fully visible
            fadeOutTimeout = setTimeout(() => {
                setLandIsWaxing(false);
            }, 2000);

            // Hide image 3 seconds after starting fade-out
            hideTimeout = setTimeout(() => {
                setLandIsVisible(false);
            }, 5000);
        }

        return () => {
            clearTimeout(fadeOutTimeout);
            clearTimeout(hideTimeout);
        };
    }, [landIsWaxing]);

    const [pixelIsWaxing, setPixelIsWaxing] = useState(false);
    const [pixelIsVisible, setPixelIsVisible] = useState(true);

    const handlePixelMouseOver = () => () => {
        setPixelIsWaxing(true);
        setPixelIsVisible(true);
    };

    useEffect(() => {
        let fadeOutTimeout;
        let hideTimeout;

        if (pixelIsWaxing) {
            // Start fade-out after 2 seconds of being fully visible
            fadeOutTimeout = setTimeout(() => {
                setPixelIsWaxing(false);
            }, 2000);

            // Hide image 3 seconds after starting fade-out
            hideTimeout = setTimeout(() => {
                setPixelIsVisible(false);
            }, 5000);
        }

        return () => {
            clearTimeout(fadeOutTimeout);
            clearTimeout(hideTimeout);
        };
    }, [pixelIsWaxing]);

    const [sootIsWaxing, setSootIsWaxing] = useState(false);
    const [sootIsVisible, setSootIsVisible] = useState(true);

    const handleSootMouseOver = () => () => {
        setSootIsWaxing(true);
        setSootIsVisible(true);
    };

    useEffect(() => {
        let fadeOutTimeout;
        let hideTimeout;

        if (sootIsWaxing) {
            // Start fade-out after 2 seconds of being fully visible
            fadeOutTimeout = setTimeout(() => {
                setSootIsWaxing(false);
            }, 2000);

            // Hide image 3 seconds after starting fade-out
            hideTimeout = setTimeout(() => {
                setSootIsVisible(false);
            }, 5000);
        }

        return () => {
            clearTimeout(fadeOutTimeout);
            clearTimeout(hideTimeout);
        };
    }, [sootIsWaxing]);

    const [philipsIsWaxing, setPhilipsIsWaxing] = useState(false);
    const [philipsIsVisible, setPhilipsIsVisible] = useState(true);

    const handlePhilipsMouseOver = () => () => {
        setPhilipsIsWaxing(true);
        setPhilipsIsVisible(true);
    };

    useEffect(() => {
        let fadeOutTimeout;
        let hideTimeout;

        if (philipsIsWaxing) {
            // Start fade-out after 2 seconds of being fully visible
            fadeOutTimeout = setTimeout(() => {
                setPhilipsIsWaxing(false);
            }, 2000);

            // Hide image 3 seconds after starting fade-out
            hideTimeout = setTimeout(() => {
                setPhilipsIsVisible(false);
            }, 5000);
        }

        return () => {
            clearTimeout(fadeOutTimeout);
            clearTimeout(hideTimeout);
        };
    }, [philipsIsWaxing]);

    return (
        <div className="resume">
            <header className="header">
                <a href="https://www.linkedin.com/in/ebeiler" target="_blank" class="linkedin-link">
                    <img src="https://cdn-icons-png.flaticon.com/512/174/174857.png" alt="LinkedIn" class="linkedin-icon" />
                </a>
                <h1>Eric Beiler</h1>
                <p className="tight">Austin, TX 78732</p>
                <p className="tight">(216) 246-7866 | <a href="mailto:eric.beiler@gmail.com" class="mailto-link">eric.beiler@gmail.com</a></p>
            </header>
            <section className="text-carousel">
                <h2>Software Engineering Leader</h2>
                <p className="tight-centered-emphasis">Multifaceted technical career with a 20-year track record of innovation and success</p>
            </section>
            <section>
                <p>Experienced engineering leader with a proven track record of delivering successful software
                    solutions across diverse industries. Skilled in managing international teams, resource allocation,
                    scheduling, and budgeting. Proficient in the end-to-end Software Development Lifecycle, including
                    architecture, design, verification, and maintenance. Seeking leadership opportunities in innovative organizations.
                </p>
                <p className="centered-emphasis">
                    Secure Software Design & Development / Software Project Management / Agile Methodologies / Cybersecurity Software Automation, DevOps
                    / Machine Learning / Cloud-based Solutions / Automation and Controls
                </p>
            </section>
            <h2>PROFESSIONAL EXPERIENCE</h2>
            <section>
                <div className="hover-section" onMouseOver={handleIflexMouseOver()}>
                    <div className="job-container2">
                        <div className="job-header2">
                            <div className="job-title2">Senior Software Engineering Manager</div>
                            <div className="date2">(2/2019 - Present)</div>
                            <div className="company2"><a href="https://int.diasorin.com/en/luminex-ltg">Luminex Corporation</a>, Austin, TX</div>
                        </div>
                    </div>
                    <p className="technical-scope"><span className="bold">Technical Scope:</span> Microsoft Azure and Azure DevOps, SQL Server, C#, PowerShell</p>
                    {iflexIsVisible && (
                        <div className={`hover-image-container ${iflexIsWaxing ? 'fade-in' : 'fade-out'}`}>
                            <img className="hover-image" src={'Intelliflex.png'} />
                        </div>
                    )}
                    <p className="job-description">
                        Leading a diverse team of 20+ professionals, including software developers,
                        designers, testers, business analysts, and technical writers, in the development
                        of the <a href="https://int.diasorin.com/en/luminex-ltg/tools/xmap-intelliflex"> INTELLIFLEX System</a>. This greenfield project is built using .NET Core, WPF,
                        ASP.Net, and SQL Server.</p>
                    <ul>
                        <li>Led efforts with cross-functional groups to construct requirements for the INTELLIFLEX</li>
                        <li>Directed internationally distributed teams and worked with executive management on setting schedules</li>
                        <li>Managed talent acquisition and hiring to scale team to proper size for project</li>
                        <li>Led development design reviews and architectural decisions for family of instruments</li>
                        <li>Spearheaded the move of the software development and testing environment to cloud-based Microsoft Azure and Azure DevOps and implemented the transition</li>
                        <li>Facilitated the training of the test team with the skills to build and integrate automated test pipelines</li>
                        <li>Orchestrated Agile methodologies and sprints within a broader FDA regulated framework</li>
                        <li>Consolidated design and operating processes between Italian and US based teams after DiaSorin merger</li>
                        <li>Led successful system transformation and work environment in response to Covid Pandemic</li>
                    </ul>
                </div>

                <div className="hover-section" onMouseOver={handleLandMouseOver()}>
                    <div className="job-container2">
                        <div className="job-header2">
                            <div className="job-title2">Lead Software Engineer</div>
                            <div className="date2">(4/2017 - 2/2019)</div>
                            <div className="company2"><a href="https://www.costargroup.com/about-us/brands/landdotcom">CoStar Group (Lands Network)</a>, Austin, TX</div>
                        </div>
                    </div>
                    <p className="technical-scope"><span className="bold">Technical Scope:</span> Azure DevOps, ASP.NET, SQL Server, C#, PowerShell, React, Redux, JavaScript, Akamai</p>
                    {landIsVisible && (
                        <div className={`hover-image-container-right ${landIsWaxing ? 'fade-in' : 'fade-out'}`}>
                            <img className="hover-image" src={'landcom.png'} />
                        </div>
                    )}
                    <p className="job-description">Successfully lead a team of 20 developers and testers in the ground-up creation of a new <a href="https://www.land.com/">Lands Of America</a> website while also overseeing
                        the maintenance of the existing <span class="underline">Land.com</span> digital platforms. Collaborated with the team, product managers, and executive management to strategically deploy large projects.
                        Spearheaded the consolidation of teams and products as part of a merger between two previously competing websites.</p>
                    <ul>
                        <li>Hired to lead the vertical consolidation of disparate back-end systems for three distinct public-facing real estate listing sites: Lands of America, <a href="https://www.landandfarm.com/">Land And Farm</a> and <a href="https://www.landwatch.com/">Land Watch</a>.</li>
                        <li>Spearheaded a project to rewrite <span class="underline">Lands of America</span> from a ColdFusion application to a modern Single Page Application using React, Redux, and ASP.NET as the framework.</li>
                        <li>Acted as a system architect, involved in development using React, Redux, ASP.NET, and SQL Server.</li>
                        <li>Successfully scaled the software development team by doubling the size of both the developer and test teams through strategic hiring.</li>
                        <li>Collaborated with executive management to establish a long-term vision for the future of the sites.</li>
                        <li>Oversaw the consolidation effort to merge the backend systems of <span class="underline">Lands of America</span> and <span class="underline">Land And Farm</span> into a single, unified SQL Database.</li>
                        <li>Led a team of 20 in updating <span class="underline">Lands of America</span> and <span class="underline">Land and Farm</span> Sites with a 3 week sprint cadence.</li>
                        <li>Worked closely with the DevOps team to design software configurations for Akamai CDN, ensuring optimal delivery speed.</li>
                        <li>Collaborated with the cybersecurity team to analyze F5 reports, diagnose threats, and implement countermeasures.</li>
                    </ul>
                </div>

                <div className="hover-section" onMouseOver={handlePixelMouseOver()}>
                    <div className="job-container2">
                        <div className="job-header2">
                            <div className="job-title2">Director of Software Engineering</div>
                            <div className="date2">(12/2015 - 04/2017)</div>
                            <div className="company2"><span className="underline">Pixel Velocity</span>, Austin, TX</div>
                        </div>
                    </div>
                    <p className="technical-scope"><span className="bold">Technical Scope:</span> Microsoft Azure, Web API/REST, C#</p>
                    {pixelIsVisible && (
                        <div className={`hover-image-container ${pixelIsWaxing ? 'fade-in' : 'fade-out'}`}>
                            <img className="hover-image" src={'pixel.png'} />
                        </div>
                    )}
                    <p className="job-description">Directed a team of software developers located in Texas, Michigan and India in the architecture and development of Pixel Velocity's
                        video monitoring and analytical software suite.</p>
                    <ul>
                        <li>Integrated and successfully delivered Pixel's newest product, Event Velocity, a video monitoring web application, as part of the initial pilot.</li>
                        <li>Spearheaded the re-engineering of Pixel's software processes, proposing and gaining buy-in for key changes. Commissioned Visual Studio Team Services to automate the software delivery process, encompassing
                            continuous integration, unit testing, delivery, and packaging. Utilized VSTS for collaborative work item and defect prioritization through dashboards and a virtual task board.</li>
                        <li>Provided training and coaching to junior employees, fostering their understanding of systems and best practices, resulting in a knowledgeable, skilled, and cohesive team.</li>
                        <li>Established a virtual network of machines, web services, and applications within Microsoft Azure, enhancing flexibility, scalability, and testability across the Pixel-Velocity suite of software solutions.</li>
                        <li>Led the development of multiple software applications and components, guiding them through requirements/analysis, design, implementation, and testing phases of the software development process.</li>
                    </ul>
                </div>

                <div className="hover-section" onMouseOver={handleSootMouseOver()}>
                    <div className="job-container2">
                        <div className="job-header2">
                            <div className="job-title2">Lead Software Engineer</div>
                            <div className="date2">(07/2007 - 12/2015)</div>
                            <div className="company2"><a href="https://www.gevernova.com/steam-power/services/digital/boiler-monitoring">Neuco Inc/GE.</a>, Austin, TX</div>
                        </div>
                    </div>
                    <p className="technical-scope"><span className="bold">Technical Scope:</span> C#, Microsoft SQL, IIS, Neural Networks, Machine Learning, Microsoft Office Integration</p>
                    {sootIsVisible && (
                        <div className={`hover-image-container-right ${sootIsWaxing ? 'fade-in' : 'fade-out'}`}>
                            <img className="hover-image" src={'soot.png'} />
                        </div>
                    )}
                    <p className="job-description">Provided leadership for Neuco's SootOpt later bought and rebranded as <a href="https://www.gevernova.com/steam-power/services/digital/boiler-monitoring">GE BoilerOpt</a>. Led the research data modeling and control techniques
                        in optimizing the cleaning of boilers using machine learning and neural networks. Coached junior employees on emerging technologies and best practices. Led problem
                        solving efforts of client challenges with stakeholders from
                        multiple disciplines.</p>
                    <ul>
                        <li>Applied agile methodologies to improve the product development process, introducing Team Foundation Server to continuously integrate the software and prioritize
                            defects and features.</li>
                        <li>Supported installation of the first prototype at functioning power-plant, involving the diagnoses of problems, adjustment of requirements, and quality assurance
                            of neural network modelling and system control.</li>
                        <li>Served as lead developer in the creation and integration of a reporting system for Neuco clients.</li>
                        <li>Implemented new application of neural network paired with a statistical analysis to identify the effectiveness of the cleaning application, resulting in reduced maintenance,
                            2 - 5% improvements in efficiency and up to millions of dollars in measurable fuel savings for our customers.</li>
                    </ul>
                </div>

                <div className="hover-section" onMouseOver={handlePhilipsMouseOver()}>
                    <div className="job-container2">
                        <div className="job-header2">
                            <div className="job-title2">Senior Software Engineer</div>
                            <div className="date2">(7/2005 - 4/2007)</div>
                            <div className="company2"><a href="https://www.usa.philips.com/healthcare/solutions/computed-tomography">Philips Medical Systems (CT Division)</a>, Cleveland, OH</div>
                        </div>
                    </div>
                    <p className="technical-scope"><span className="bold">Technical Scope:</span> C#, C++</p>
                    {philipsIsVisible && (
                        <div className={`hover-image-container ${philipsIsWaxing ? 'fade-in' : 'fade-out'}`}>
                            <img className="hover-image" src={'philips.png'} />
                        </div>
                    )}
                    <p className="job-description">Served as senior software lead in the development of diagnostic and monitoring software for CT machines. Scaled engineering teams across U.S., China and India. Led daily scrum stand-up meetings. Supervised in-house
                        and off-shore engineers in the design and development of the monitoring system's components. Collaborated with Philips Global to standardize the monitoring system across multiple modalities, including MRI, X-Ray, ultrasound, and others.</p>
                    <ul>
                        <li>Lead the design and development of a cloud-based enterprise data monitoring system for CT systems using Scrum and Agile methodologies.</li>
                        <li>Interviewed, recruited and trained international team of developers to augment the in-house staff in the development and integration of modules
                            into the CT monitoring and diagnostic software system.</li>
                        <li>Led multiple engineering teams to standardize a monitoring and diagnostic system across multiple imaging modalities while allowing each to maintain
                            domain specific features.</li>
                    </ul>
                </div>

                <div className="job-container2">
                    <div className="job-header2">
                        <div className="job-title2">Industrial Automation & Control Engineer</div>
                        <div className="date2">(7/2005 - 4/2007)</div>
                        <div className="company2"><a href="https://www.controlsoftinc.com/"></a>ControlSoft, Inc., Cleveland, OH</div>
                    </div>
                </div>
                <div className="job-container">
                    <table className="job-header"><thead><tr><td className="job-title"></td><td className="date"></td><td className="company"></td></tr></thead></table>
                </div>
                <p className="technical-scope"><span className="bold">Technical Scope:</span> C#, C++, Embedded Software, PLCs, Advanced Process Automation & Controls</p>
                <p className="job-description"></p>
                <ul>
                    <li>Contracted with Rockwell Automation, GE Fanuc & Dow Chemical to implement advanced control systems on a variety of projects including water treatment, cleanroom environment and space simulation chamber.</li>
                    <li>Designed and implemented portions of the INTUNE and MANTRA products, including the <a href="https://opcfoundation.org/">OPC</a> interfaces for both products.</li>
                </ul>
            </section>
            <section className="education">
                <h2>EDUCATION AND TRAINING</h2>
                <h3 className="tight-centered">Masters of Science in Electrical/Computer Engineering</h3>
                <p className="tight-centered">Cleveland State University, Cleveland OH, 2002</p>
                <h3 className="tight-centered">Bachelor of Science in Electrical Engineering</h3>
                <p className="tight-centered">Case Western Reserve University, Cleveland OH</p>
            </section>
            <section className="certifications">
                <h2>CERTIFICATIONS</h2>
                <p className="tight-centered">Azure AI Engineer Certification | AI-102 & AI-900</p>
                <p className="tight-centered">Certified Scrum Master | CSM</p>
            </section>
        </div>
    );
}

export default App;
